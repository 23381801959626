import React, { useEffect, useState, useContext } from "react";
import ViewHeaderTitle from "../../../components/navigation/ViewHeaderTitle";

import { getAuth } from "firebase/auth";

import { AuthContext } from "./../../../contexts/AuthContext";

//Import stylesheets
import './../../../assets/stylesheets/portal.css'
import './../../../assets/stylesheets/teamSchedule.css'

//Import helpers
import { returnFormattedDateString } from "../../../helpers/generalHelper";
import { postRequest } from "../../../helpers/apiHelper";

//Import components
import ScheduleModalJob from "../../../components/portal/ScheduleModalJob";
import ScheduleModalAppointment from "../../../components/portal/ScheduleModalAppointment";
import ScheduleModalAppointmentReadOnly from "../../../components/portal/ScheduleModalAppointmentReadOnly";
import LoadingModal from "../../../components/portal/LoadingModal";
import ConfirmationModal from "../../../components/portal/ConfirmationModal";

const Schedule = (props) => {

	const authValue = useContext(AuthContext);

	//State variables	
	const [inspectorObjArray, setInspectorObjArray] = useState([]);
	const [privateAppointmentRecords, setPrivateAppointmentRecords] = useState([]);
	const [jobRecordList, setJobRecordList] = useState([]);
	const [dateList, setDateList] = useState([]);
	const [weekdayList, setWeekdayList] = useState([]);

	const [scheduleModalJobVisible, setScheduleModalJobVisible] = useState(false);
	const [scheduleModalAppointmentVisible, setScheduleModalAppointmentVisible] = useState(false);
	const [scheduleModalAppointmentReadOnlyVisible, setScheduleModalAppointmentReadOnlyVisible] = useState(false);

	const [jobRecordForModal, setJobRecordForModal] = useState({});
	const [appointmentRecordForModal, setAppointmentRecordForModal] = useState({});

	const [loadingModalVisible, setLoadingModalVisible] = useState(true);
	const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);


	
	const [userRoles, setUserRoles] = useState(["Inspector"]); //Default to inspector visibility

	useEffect(() => {
		getUserRoles();
	}, []);

	const getUserRoles = async () => {		
		const userRoles = await postRequest("/getUserRoles", {});
		setUserRoles(userRoles)
	}

	const loadSchedule = () => {
		//1. Set date list
		let todaysDateObj = new Date();
		let todaysDateVal = todaysDateObj.getTime();
		let todayIndex = todaysDateObj.getDay();

		let dateList = [];
		let weekdayList = [];
		let dateValList = [];
		let weekdayNames = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday"
		]

		for (var i = 0; i < 180; i++) {
			dateList.push(returnFormattedDateString(todaysDateVal + i * 24 * 60 * 60 * 1000));
			dateValList.push(todaysDateVal + i * 24 * 60 * 60 * 1000);
			let dayIndex = (todayIndex + i) % 7;
			weekdayList.push(weekdayNames[dayIndex])
		}
		setDateList(dateList);
		setWeekdayList(weekdayList);

		//2. Make post request calls
		const getStateVariables = async () => {
			const inspectorObjArrayResponse = await postRequest("/getInspectorUserObjArray");
			setInspectorObjArray(inspectorObjArrayResponse);
			const privateAppointmentRecordsResponse = await postRequest("/getCurrentPrivateAppointmentRecords");
			setPrivateAppointmentRecords(privateAppointmentRecordsResponse);
			const getJobRecordListResponse = await postRequest("/getJobRecordListKeyAttributesOnlyOrderedByStartTime");
			console.log(getJobRecordListResponse)
			setJobRecordList(getJobRecordListResponse);
			setLoadingModalVisible(false);
		}
		getStateVariables();

	}


	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		loadSchedule();
	}, []);

	const dayHeaderCells = () => {
		let componentJSX = [];
		dateList.forEach(function (element, index) {
			componentJSX.push(
				<th key={index}>
					<p>{element}</p>
					<p>{weekdayList[index]}</p>
				</th>
			)
		});
		return componentJSX;
	}

	const tableBody = () => {
		let componentJSX = [];

		inspectorObjArray.forEach(function (inspectorUserObj, index) {
			if(userRoles.includes('Office')) {
				//Add all rows
				componentJSX.push(
					<tr key={index}>
						<td><span>{inspectorUserObj.uid}</span></td>
						{inspectorDayCells(inspectorUserObj.uid)}
					</tr>
				)
			} else {
				//Only add relevant inspector row
				if(inspectorUserObj.uid == localStorage.getItem('uid')) {
					componentJSX.push(
						<tr key={index}>
							<td><span>{inspectorUserObj.uid}</span></td>
							{inspectorDayCells(inspectorUserObj.uid)}
						</tr>
					)
				}
			}
		});
		return componentJSX;
	}

	const inspectorDayCells = (userID) => {
		let componentJSX = [];

		dateList.forEach(function (dateString, dateListIndex) {
			componentJSX.push(
				<td key={dateListIndex}>
					{inspectorAppointments(userID, dateString)}
					{inspectorJobs(userID, dateString)}
				</td>
			)
		});

		return componentJSX;
	}

	const inspectorAppointments = (userID, dateString) => {
		let componentJSX = [];
		let dateArray = dateString.split("-"); // ["31", "12", "2023"]
	
		// Construct a new Date object directly
		let date = new Date(
			Number(dateArray[2]), // Year
			Number(dateArray[1]) - 1, // Month (0-indexed)
			Number(dateArray[0]), // Day
			0, 0, 0, 0 // Time set to midnight
		);
	
		let dateVal = date.getTime();
		let dayDateString = dateString;
	
		// Debugging logs
		console.log("Processing date:", dayDateString, "Date object:", date);
	
		let filteredAppointmentRecordList = privateAppointmentRecords.filter(function (itm) {
			if (itm.userID !== userID) return false;
	
			// Debugging logs for each appointment
			console.log("Checking appointment:", itm.appointmentStartDate, itm.appointmentEndDate);
			console.log("Start Val:", itm.appointmentStartDateVal, "End Val:", itm.appointmentEndDateVal);
			console.log("Date Val:", dateVal);
	
			return (
				itm.appointmentStartDate === dayDateString ||
				(itm.appointmentStartDateVal <= dateVal && itm.appointmentEndDateVal >= dateVal)
			);
		});
	
		filteredAppointmentRecordList.forEach(function (appointmentRecord, index) {
			if (appointmentRecord.appointmentType === 'Job hold') {
				componentJSX.push(
					<div key={index} className="m-2">
						<button
							className="btn btn-warning btn-rounded btn-sm m-0 teamScheduleAppointmentBtn"
							onClick={() => {
								setAppointmentRecordForModal(appointmentRecord);
								userRoles.includes('Office')
									? setScheduleModalAppointmentVisible(true)
									: setScheduleModalAppointmentReadOnlyVisible(true);
							}}
						>
							<span style={{ color: 'black' }}>{appointmentRecord.appointmentType}</span>
							<br />
							<span style={{ color: 'black' }}>
								{appointmentRecord.appointmentStartTime}-{appointmentRecord.appointmentEndTime}
							</span>
						</button>
					</div>
				);
			} else {
				componentJSX.push(
					<div key={index} className="m-2">
						<button
							className="btn btn-danger btn-rounded btn-sm m-0 teamScheduleAppointmentBtn"
							onClick={() => {
								setAppointmentRecordForModal(appointmentRecord);
								userRoles.includes('Office')
									? setScheduleModalAppointmentVisible(true)
									: setScheduleModalAppointmentReadOnlyVisible(true);
							}}
						>
							<span>{appointmentRecord.appointmentType}</span>
							<br />
							<span>
								{appointmentRecord.appointmentStartTime}-{appointmentRecord.appointmentEndTime}
							</span>
						</button>
					</div>
				);
			}
		});
	
		return componentJSX;
	};
	

	const inspectorJobs = (userID, dateString) => {
		let componentJSX = [];
		let filteredJobRecordList = jobRecordList.filter(function (itm) {
			return itm.inspectorUserID === userID && itm.inspectionDate === dateString
		});

		filteredJobRecordList.forEach(function (jobRecord, index) {
			componentJSX.push(
				<div key={index} className="m-2">
					<button className="btn btn-info btn-rounded btn-sm m-0 teamScheduleJobBtn" onClick={() => {
						setJobRecordForModal(jobRecord);
						setScheduleModalJobVisible(true);
					}} >
						<span>{jobRecord.inspectionStartTime}-{jobRecord.inspectionEndTime}</span>
						<br />
						<span>JobID: {jobRecord.jobID}</span>
					</button>
				</div>
			)
		});

		return componentJSX;
	}



	const returnAndLoadSchedule = () => {
		loadSchedule();
		setScheduleModalJobVisible(false);
	}

	return (
		<>
			
			<LoadingModal visible={loadingModalVisible} />
      		<ConfirmationModal visible={confirmationModalVisible} setConfirmationModalVisible={setConfirmationModalVisible} />

			<ScheduleModalJob visible={scheduleModalJobVisible} jobRecordForModal={jobRecordForModal} onClose={() => { setScheduleModalJobVisible(false) }} />
			<ScheduleModalAppointment visible={scheduleModalAppointmentVisible} appointmentRecordForModal={appointmentRecordForModal} onClose={() => { setScheduleModalAppointmentVisible(false) }} setLoadingModalVisible={setLoadingModalVisible} setConfirmationModalVisible={setConfirmationModalVisible} returnAndLoadSchedule={returnAndLoadSchedule} />
			<ScheduleModalAppointmentReadOnly visible={scheduleModalAppointmentReadOnlyVisible} appointmentRecordForModal={appointmentRecordForModal} onClose={() => { setScheduleModalAppointmentReadOnlyVisible(false) }} setLoadingModalVisible={setLoadingModalVisible} setConfirmationModalVisible={setConfirmationModalVisible} returnAndLoadSchedule={returnAndLoadSchedule} />

			<ViewHeaderTitle title={props.headerTitle} />

			{userRoles.includes('Office') && (
				<>
					<div className="d-flex mt-4">
						<button className="btn btn-success btn-rounded btn-sm m-0" type="button" onClick={() => { setAppointmentRecordForModal(null); setScheduleModalAppointmentVisible(true) }}>
							<i className="fa fa-plus-circle"></i>
							<span> Set private appointment</span>
						</button>
					</div>
					<br />
				</>
			)}
			<div className="team-schedule-table" id="teamScheduleTable">
				<table className="table table-bordered table-sm scheduleTable">
					<thead className="thead-light">
						<tr>
							<th>Inspector</th>
							{dayHeaderCells()}
						</tr>
					</thead>
					<tbody>
						{tableBody()}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default Schedule;
