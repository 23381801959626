import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import PropTypes from "prop-types"; // Optional: For prop type validation

const UserTableDeleteButton = ({
  tooltipText = "Delete user",
  onClick, // Function to execute after confirmation
}) => {
  // Handler for button click
  const handleClick = () => {
    const confirmed = window.confirm(
      "You will be permanently deleting a user. Are you sure?"
    );
    if (confirmed) {
      onClick();
    }
  };

  // Inline styles for purple background
  const buttonStyle = {
    backgroundColor: "#6f42c1", // Bootstrap's purple shade
    color: "white",
    border: "none",
    display: "flex",
    alignItems: "center",
    padding: "0.375rem 0.75rem",
    fontSize: "0.875rem",
    borderRadius: "0.2rem",
    cursor: "pointer",
  };

  const buttonHoverStyle = {
    backgroundColor: "#5936a2", // Darker shade for hover effect
  };

  return (
    <div className="d-flex gap-3">
      <div className="view-job">
        <OverlayTrigger
          overlay={(props) => <Tooltip {...props}>{tooltipText}</Tooltip>}
          placement="bottom"
        >
          <button
            type="button"
            onClick={handleClick}
            style={buttonStyle}
            onMouseOver={(e) => {
              Object.assign(e.target.style, buttonHoverStyle);
            }}
            onMouseOut={(e) => {
              Object.assign(e.target.style, { backgroundColor: "#6f42c1" });
            }}
          >
            {/* Using Font Awesome icon */}
            <i className="fas fa-exclamation-triangle me-2" aria-hidden="true"></i>
            
          </button>
        </OverlayTrigger>
      </div>
    </div>
  );
};

// Optional: Prop type validation
UserTableDeleteButton.propTypes = {
  tooltipText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default UserTableDeleteButton;
